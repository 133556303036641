import axios from 'axios'
import helpers from 'mixins/mixin-helpers'
import { date } from 'quasar'

let cancelTokenSourceTenders
let cancelTokenSourceTenderPublic
let cancelTokenSourceTenderInvited
let cancelTokenSourceTenderSubscription

function transformOrLikeCatPrevalente (array) {
  let catPrevalente = []
  array.map(value => catPrevalente.push({
      $like: value
    }
  ))
  return catPrevalente
}

const getDefaultState = () => {
  return {
    tenders: {
      data: [],
      loading: false,
      loaded: false
    },
    publicCard: {
      data: [],
      loading: false,
      loaded: false
    },
    invitedCard: {
      data: [],
      loading: false,
      loaded: false
    },
    subscriptionCard: {
      data: [],
      loading: false,
      loaded: false
    },
    tendersCruscottoPublic: {
      data: [],
      meta: []
    },
    tendersCruscottoInvites: {
      data: [],
      meta: []
    },
    tendersCruscottoSubscriptions: {
      data: [],
      meta: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateTenders (state) {
    Object.assign(state, getDefaultState())
  },
  setDataTenders (state, value) {
    state.tenders.data = value
  },
  setLoadingTenders (state, value) {
    state.tenders.loading = value
  },
  setLoadedTenders (state, value) {
    state.tenders.loaded = value
  },
  setDataPublicCard (state, value) {
    state.publicCard.data = value
  },
  setLoadingPublicCard (state, value) {
    state.publicCard.loading = value
  },
  setLoadedPublicCard (state, value) {
    state.publicCard.loaded = value
  },
  setDataInvitedCard (state, value) {
    state.invitedCard.data = value
  },
  setLoadingInvitedCard (state, value) {
    state.invitedCard.loading = value
  },
  setLoadedInvitedCard (state, value) {
    state.invitedCard.loaded = value
  },
  setDataSubscriptionCard (state, value) {
    state.subscriptionCard.data = value
  },
  setLoadingSubscriptionCard (state, value) {
    state.subscriptionCard.loading = value
  },
  setLoadedSubscriptionCard (state, value) {
    state.subscriptionCard.loaded = value
  },
  setPreferTender (state, index) {
    if (!helpers.methods.isUndefined(state.tenders.data[index]) && !helpers.methods.isArrayOrObjectDefinedNonEmpty(state.tenders.data[index])) {
      state.tenders.data[index].prefer = !state.tenders.data[index].prefer
    }
    if (!helpers.methods.isUndefined(state.tendersCruscottoPublic.data[index]) && !helpers.methods.isArrayOrObjectDefinedNonEmpty(state.tendersCruscottoPublic.data[index])) {
      state.tendersCruscottoPublic.data[index].prefer = !state.tendersCruscottoPublic.data[index].prefer
    }
    if (!helpers.methods.isUndefined(state.tendersCruscottoSubscriptions.data[index]) && !helpers.methods.isArrayOrObjectDefinedNonEmpty(state.tendersCruscottoSubscriptions.data[index])) {
      state.tendersCruscottoSubscriptions.data[index].prefer = !state.tendersCruscottoSubscriptions.data[index].prefer
    }
    if (!helpers.methods.isUndefined(state.tendersCruscottoInvites.data[index]) && !helpers.methods.isArrayOrObjectDefinedNonEmpty(state.tendersCruscottoInvites.data[index])) {
      state.tendersCruscottoInvites.data[index].prefer = !state.tendersCruscottoInvites.data[index].prefer
    }
  },
  setPreferInfoBandi (state, index) {
    if (!helpers.methods.isUndefined(state.infoBandi.data[index]) && !helpers.methods.isArrayOrObjectDefinedNonEmpty(state.infoBandi.data[index])) {
      state.infoBandi.data[index].prefer = !state.infoBandi.data[index].prefer
    }
  },
  setPreferTenderResult (state, index) {
    if (!helpers.methods.isUndefined(state.tenderResult.data[index]) && !helpers.methods.isArrayOrObjectDefinedNonEmpty(state.tenderResult.data[index])) {
      state.tenderResult.data[index].prefer = !state.tenderResult.data[index].prefer
    }
  }
}

const actions = {
  resetStateTenders ({ commit }) {
    commit('resetStateTenders')
  },
  loadData ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingTenders', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceTenders) {
      cancelTokenSourceTenders.cancel()
    }
    // Get a new token
    cancelTokenSourceTenders = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilterTender = rootState.filters.tenders
    let stateFilterTenderPagination = rootState.paginations.tenders
    let query = {
      query: {
        $or: [
          {
            oggetto: {
              $like: stateFilterTender.cigOggetto
            }
          },
          {
            cig: {
              $like: stateFilterTender.cigOggetto
            }
          }
        ],
        data_pubblicazione: [],
        nome_ente: {
          $like: stateFilterTender.nome_ente
        },
        stato: {
          $or: stateFilterTender.stato
        },
        tipologia_art190: {
          $or: stateFilterTender.tipologia_art190
        },
        cat_prevalente: {
          $or: transformOrLikeCatPrevalente(stateFilterTender.cat_prevalente)
        },
        type_ente: {
          $or: stateFilterTender.type_ente
        },
        regione: {
          $or: stateFilterTender.regione
        }
      }
    }
    if (stateFilterTender.data_pubblicazione_from) {
      query.query.data_pubblicazione.push({ $gte: stateFilterTender.data_pubblicazione_from })
    }
    if (stateFilterTender.data_pubblicazione_to) {
      let dateTo = new Date(stateFilterTender.data_pubblicazione_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.data_pubblicazione.push({ $lte: dateTo })
    }

    switch (stateFilterTender.status_type) {
      case 0:
        break
      case 1:
        query.query.$having = [
          { 'is_subscribed': 1 }
        ]
        break
      case 2:
        query.query.$having = [
          { 'is_invited': 1 }
        ]
        break
      default:
        break
    }

    let config = {
      params: {
        $limit: stateFilterTenderPagination.rowsPerPage,
        $offset: stateFilterTenderPagination.rowsOffset,
        $sort: [
          'prefer DESC',
          stateFilterTenderPagination.sortBy + ' ' + (stateFilterTenderPagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceTenders.token
    }
    let url = stateSettingUrl.networkpa_tender_all
    return axios.get(url, config)
      .then((response) => {
        commit('setDataTenders', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'tenders',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingTenders', false)
        commit('setLoadedTenders', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataTenders', [])
          commit('setLoadingTenders', false)
          return false
        }
      })
  },
  loadDataPublicCard ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingPublicCard', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceTenderPublic) {
      cancelTokenSourceTenderPublic.cancel()
    }
    // Get a new token
    cancelTokenSourceTenderPublic = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let statePagination = rootState.paginations.tenderPublicCard
    let query = {
      query: {}
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceTenderPublic.token
    }
    let url = stateSettingUrl.networkpa_tender_public
    return axios.get(url, config)
      .then((response) => {
        commit('setDataPublicCard', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'tenderPublicCard',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingPublicCard', false)
        commit('setLoadedPublicCard', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataPublicCard', [])
          commit('setLoadingPublicCard', false)
          return false
        }
      })
  },
  loadDataInvitedCard ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingInvitedCard', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceTenderInvited) {
      cancelTokenSourceTenderInvited.cancel()
    }
    // Get a new token
    cancelTokenSourceTenderInvited = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let statePagination = rootState.paginations.tenderInvitedCard
    let query = {
      query: {}
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceTenderInvited.token
    }
    let url = stateSettingUrl.networkpa_tender_invites
    return axios.get(url, config)
      .then((response) => {
        commit('setDataInvitedCard', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'tenderInvitedCard',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingInvitedCard', false)
        commit('setLoadedInvitedCard', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataInvitedCard', [])
          commit('setLoadingInvitedCard', false)
          return false
        }
      })
  },
  loadDataSubscriptionCard ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingSubscriptionCard', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceTenderSubscription) {
      cancelTokenSourceTenderSubscription.cancel()
    }
    // Get a new token
    cancelTokenSourceTenderSubscription = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let statePagination = rootState.paginations.tenderSubscriptionCard
    let query = {
      query: {}
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceTenderSubscription.token
    }
    let url = stateSettingUrl.networkpa_tender_subscriptions
    return axios.get(url, config)
      .then((response) => {
        commit('setDataSubscriptionCard', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'tenderSubscriptionCard',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingSubscriptionCard', false)
        commit('setLoadedSubscriptionCard', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataSubscriptionCard', [])
          commit('setLoadingSubscriptionCard', false)
          return false
        }
      })
  },
  setPreferTender ({ commit, dispatch }, payload) {
    commit('setPreferTender', payload)
  },
  setPreferInfoBandi ({ commit, dispatch }, payload) {
    commit('setPreferInfoBandi', payload)
  },
  setPreferTenderResult ({ commit, dispatch }, payload) {
    commit('setPreferTenderResult', payload)
  }
}

const getters = {

  getTenders: state => {
    return Object.values(state.tenders.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getLoadingTenders: state => {
    return state.tenders.loading
  },
  getLoadedTenders: state => {
    return state.tenders.loaded
  },
  getTenderPublicCard: state => {
    return Object.values(state.publicCard.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getLoadingPublicCard: state => {
    return state.publicCard.loading
  },
  getLoadedPublicCard: state => {
    return state.publicCard.loaded
  },
  getTenderInvitedCard: state => {
    return Object.values(state.invitedCard.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getLoadingInvitedCard: state => {
    return state.invitedCard.loading
  },
  getLoadedInvitedCard: state => {
    return state.invitedCard.loaded
  },
  getTenderSubscriptionCard: state => {
    return Object.values(state.subscriptionCard.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getLoadingSubscriptionCard: state => {
    return state.subscriptionCard.loading
  },
  getLoadedSubscriptionCard: state => {
    return state.subscriptionCard.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
