import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('tenderResult', [
      'loadAllTenderResult',
      'loadData',
      'loadDataTenderResultCard'
    ])
  },
  computed: {
    ...mapGetters('tenderResult', [
      'getTenderResult',
      'getLoadingTenderResult',
      'getLoadedTenderResult',
      'getTenderResultCard',
      'getLoadingTenderResultCard',
      'getLoadedTenderResultCard'
    ])
  }
}
