export const internalFrFR = {
  'howToUse': {
    'referencing': '@:howToUse.test',
    'singularPlural': 'plurale | plurali ($tc(singularPlural,2))',
    'test': 'Test Text',
    'withParameter': "Hallo {name}! $t('lbl_', { name: 'Vue.js'})",
    'zeroOneMany': 'no zeroOneMany | one zeroOneMany | a pack of {n} zeroOneMany  ($tc(zeroOneMany,2))'
  },
  'routes': {
    'ecommerce': process.env.ECOMERCE_URL,
    'auth': '/',
    '404': '/404',
    'home': '/supplier/',
    'albi_fornitori': '/supplier/albi-fornitori',
    'area_comunicazioni': '/supplier/comunicazioni',
    'area_notifiche': '/supplier/notifiche',
    'bandi_gara': '/supplier/bandi-gara',
    'info_bandi': '/supplier/info-bandi',
    'benchmark': '/supplier/benchmark',
    'tender_result': '/supplier/tender-result',
    'cruscotto': '/supplier/cruscotto',
    'iscrizione': '/supplier/gestione-iscrizione',
    'videoguide': '/supplier/videoguide',
    'proced_invito': '/supplier/procedure-invito',
    'repliscrizione': '/supplier/replica-iscrizione',
    'sincronizzazione': '/supplier/sincronizzazione',
    'supporto_tecnico': process.env.HELP_DESK_URL + '/?ente=albinetworkpa&lang=fr',
    'internal_faq': '/supplier/faq',
    'regolamento': '/supplier/regolamento-utilizzo/',
    'user_modifier': '/supplier/user-modifier/',
    'contatti': process.env.ECOMERCE_URL + '/contatti/',
    'faq': process.env.ECOMERCE_URL + '/info-utili/',
    'network': process.env.ECOMERCE_URL + '/la-rete-networkpa/',
    'privacy': process.env.ECOMERCE_URL + '/privacy-e-cookies/',
    'servizi': process.env.ECOMERCE_URL + '/servizi-consulenza-supporto/',
    'subscription': process.env.ECOMERCE_URL + '/abbonamenti-networkpa/',
    'termini_e_condizioni': process.env.ECOMERCE_URL + '/termini-e-condizioni/'
  },
  'tables': {
    'lbl_rows': 'riga | righe',
    'lbl_selected': 'selezionata su | selezionate su'
  }
}
