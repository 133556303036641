import axios from 'axios'
import { LocalStorage } from 'quasar'
import _ from 'lodash'
import { i18n } from 'boot/i18n'
import helpers from 'mixins/mixin-helpers'

const getDefaultState = () => {
  return {
    supplierForms: {
      steps: [],
      input: {},
      countries: [],
      submitButton: false
    },
    loaded: false,
    loading: false
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateSupplierForms (state) {
    Object.assign(state, getDefaultState())
  },
  setLoadedSupplierForms (state, value) {
    state.loaded = value
  },
  setLoadingSupplierForms (state, value) {
    state.loading = value
  },
  setStepsSupplierForms (state, value) {
    state.supplierForms.steps = value
  },
  setCountriesSupplierForms (state, value) {
    state.supplierForms.countries = value
  },
  setSubmitButtonSupplierForms (state, value) {
    state.supplierForms.submitButton = value
  }
}

const actions = {
  resetStateSupplierForms ({ commit }) {
    commit('resetStateSupplierForms')
  },
  setLoadedSupplierForms ({ commit, dispatch }, value) {
    commit('setLoadedSupplierForms', value)
  },
  setLoadingSupplierForms ({ commit, dispatch }, value) {
    commit('setLoadingSupplierForms', value)
  },
  setStepsSupplierForms ({ commit, dispatch }, value) {
    commit('setStepsSupplierForms', value)
  },
  setCountriesSupplierForms ({ commit, dispatch }, value) {
    commit('setCountriesSupplierForms', value)
  },
  setSubmitButtonSupplierForms ({ commit, dispatch }, value) {
    commit('setSubmitButtonSupplierForms', value)
  },
  loadSupplierForms ({ commit, rootGetters, dispatch }) {
    commit('setLoadingSupplierForms', true)
    commit('setCountriesSupplierForms', LocalStorage.getItem('countrieSupplierForm'))
    return axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/supplier_form_config')
      .then((response) => {
        commit('setStepsSupplierForms', response.data.data.supplier_form)
        commit('setLoadedSupplierForms', true)
        commit('setCountriesSupplierForms', response.data.data.countries)
        LocalStorage.set('countrieSupplierForm', response.data.data.countries)
        // Loading.hide()
        commit('setLoadingSupplierForms', false)
      })
      .catch((error) => {
        commit('setStepsSupplierForms', [])
        commit('setLoadedSupplierForms', false)
        commit('setLoadingSupplierForms', false)
        // Loading.hide()
        return JSON.parse(JSON.stringify(error))
      })
  },
  saveSupplierForms ({ commit, state, dispatch, rootGetters }, syncro) {
    commit('setLoadingSupplierForms', true)
    dispatch('setSubmitButtonSupplierForms', true)
    const url = process.env.POST_URL !== undefined ? process.env.POST_URL : process.env.BASE_URL + '/npa/save-supplier-form'
    let postData = _.cloneDeep(state.supplierForms.steps)
    if (postData['step-categorie'] !== undefined &&
      postData['step-categorie']['groups'] !== undefined &&
      postData['step-categorie']['groups']['cat-merceologiche'] !== undefined &&
      postData['step-categorie']['groups']['cat-merceologiche']['fields'] !== undefined &&
      postData['step-categorie']['groups']['cat-merceologiche']['fields']['Categorie'] !== undefined) {
      delete postData['step-categorie']['groups']['cat-merceologiche']['fields']['Categorie']['items']
      delete postData['step-categorie']['groups']['cat-merceologiche']['fields']['Categorie']['itemsForTree']
      delete postData['step-categorie']['groups']['cat-merceologiche']['fields']['Categorie']['addDialog']
      delete postData['step-categorie']['groups']['cat-merceologiche']['fields']['Categorie']['visibleColumns']
    }
    return axios.post(url, postData) // state.supplierForms.steps
      .then(() => {
        dispatch('loadSupplierForms')
          .then(() => {
            dispatch('setSubmitButtonSupplierForms', false)
            if (syncro) {
              let selectedSyncroSubscriptions = rootGetters['installations/getStateInstallationsWithSyncroSubscription']
              if (!helpers.methods.isArrayOrObjectDefinedNonEmpty(selectedSyncroSubscriptions)) {
                this.$router.push(i18n.t('routes.sincronizzazione')).catch(() => {})
              } else {
                dispatch('syncro/sendSyncroSubscription', selectedSyncroSubscriptions, { root: true })
              }
              commit('setLoadingSupplierForms', false)
              // Loading.hide()
            } else {
              commit('setLoadingSupplierForms', false)
              // Loading.hide()
            }
            return 'Dati inviati correttamente'
          })
          .catch((error) => {
            commit('setStepsSupplierForms', [])
            dispatch('setSubmitButtonSupplierForms', false)
            // Loading.hide()
            commit('setLoadingSupplierForms', false)
            return JSON.parse(JSON.stringify(error))
          })
      })
      .catch((error) => {
        commit('setStepsSupplierForms', [])
        dispatch('setSubmitButtonSupplierForms', false)
        commit('setLoadingSupplierForms', false)
        // Loading.hide()
        return JSON.parse(JSON.stringify(error))
      })
  }
}

const getters = {
  supplierForms: state => {
    return state.supplierForms
  },
  supplierFormsLoaded: state => {
    return state.loaded
  },
  getLoadingSupplierForms: state => {
    return state.loading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
