let baseUrl = process.env.BASE_URL !== undefined ? process.env.BASE_URL : 'https://test1.networkpa.it'
let ecommerceUrl = process.env.ECOMERCE_URL !== undefined ? process.env.ECOMERCE_URL : 'https://stage.networkpa.it'
let wsBenchmark = '/ws/benchmark'

const url = {
  base_url: baseUrl,
  albo_step_riepilogo: '/profilo/modifica?step=step-riepilogo',
  networkpa_check: baseUrl + '/networkpa_check',
  networkpa_sync_credential: baseUrl + '/networkpa_sync',
  networkpa_desync_credential: baseUrl + '/networkpa_desync',
  networkpa_sync_subscription: baseUrl + '/networkpa_sync_subscription',
  networkpa_tender_all: baseUrl + '/npa/tender',
  networkpa_info_bandi_all: baseUrl + '/npa/tender_external',
  networkpa_tender_result_all: baseUrl + '/npa/tender_result',
  networkpa_tender_public: baseUrl + '/npa/tender-public',
  networkpa_tender_invites: baseUrl + '/npa/tender-invites',
  networkpa_tender_subscriptions: baseUrl + '/npa/tender-subscriptions',
  networkpa_messages_all: baseUrl + '/npa/messages',
  ws_benchmark_gara: baseUrl + wsBenchmark + '/gara/',
  ws_benchmark_gare: baseUrl + wsBenchmark + '/gare',
  ws_benchmark_operatori_economici: baseUrl + wsBenchmark + '/aziende/',
  ws_benchmark_stazione_appaltante: baseUrl + wsBenchmark + '/strutture-proponenti/',
  ws_benchmark_user_info: baseUrl + wsBenchmark + '/user/info',
  networkpa_new_token: baseUrl + '/networkpa_new_token',
  networkpa_configurations: baseUrl + '/npa/get_configuration',
  networkpa_translations: baseUrl + '/npa/get_translations',
  networkpa_preference: baseUrl + '/npa/set-preference',
  paperboy_set_readed: baseUrl + '/pb-news/readed/',
  reset_discard: ecommerceUrl + '/profilo/password-persa/',
  create_account: ecommerceUrl + '/profilo/',
  edit_account: ecommerceUrl + '/profilo/modifica-profilo/',
  your_subscription: ecommerceUrl + '/profilo/abbonamenti/',
  subscription_plus: ecommerceUrl + '/prodotto/abbonamento-iscrizione-unica/'
}

export { url }
