import BackendAPI from '../classes/backendAPI'
import Vue from 'vue'
import { url } from '../classes/settingsStatic'

const getDefaultState = () => {
  return {
    benchmark: {
      data: [],
      loading: false,
      loaded: false
    }
  }
}

const api = new BackendAPI({
  baseURL: url.ws_benchmark_gare
})

// initial state
const state = getDefaultState()

const mutations = {
  resetStateBenchmark (state) {
    Object.assign(state, getDefaultState())
  },
  setDataBenchmark (state, value) {
    state.benchmark.data = value
  },
  setLoadingBenchmark (state, value) {
    state.benchmark.loading = value
  },
  setLoadedBenchmark (state, value) {
    state.benchmark.loaded = value
  }
}

const actions = {
  resetStateBenchmark ({ commit }) {
    commit('resetStateBenchmark')
  },
  loadData ({ commit, rootState, dispatch }) {
    commit('setLoadingBenchmark', true)
    let data = {
      pagination: rootState.paginations.benchmark,
      filter: rootState.filters.benchmark
    }

    return api.post(undefined, data)
      .then((response) => {
        commit('setDataBenchmark', { ...response.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'benchmark',
          key: 'rowsNumber',
          value: response.total
        }, { root: true })
        dispatch('user/loadBenchmark190', true, { root: true })
        commit('setLoadedBenchmark', true)
        commit('setLoadingBenchmark', false)
        return response
      })
      .catch(function (error) {
        const message = error.message
        const response = error.response

        if (response !== undefined) {
          if (response.status !== 499) {
            Vue.prototype.$q.dialog({
              component: 'DialogQueryFinished',
              parent: this,
              text: message
            })
            commit('setDataBenchmark', [])
            commit('setLoadingBenchmark', false)
          }
        } else {
          console.log(error)
        }
        return false
      })
  }
}

const getters = {
  getBenchmark: state => {
    return Object.values(state.benchmark.data)
  },
  getLoadingBenchmark: state => {
    return state.benchmark.loading
  },
  getLoadedBenchmark: state => {
    return state.benchmark.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
