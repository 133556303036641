import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('filters', [
      'setFilters',
      'setFilterApplied',
      'resetFilters',
      'applyFilters',
      'resetSelectedSyncro',
      'resetSelectSyncro',
      'setSelectedSyncro',
      'loadFilterAggiudicatari',
      'loadFilterStazioneAppaltante'
    ])
  },
  computed: {
    ...mapGetters('filters', [
      'getFilters',
      'getFilterApplied',
      'getSelectedSyncro',
      'isSelectedSyncro',
      'getCanSyncroAccount',
      'getSelectedSyncroAccount',
      'getCanSyncroSubscription',
      'getSelectedSyncroSubscription',
      'getSelectedSyncroSubscriptionDisabled'
    ]),
    // filters: {
    //   get () {
    //     return this.getFilters
    //   }
    // },
    // filtersInstallations: {
    //   get () {
    //     return this.getFiltersInstallations
    //   },
    //   set (value) {
    //     this.setInstallations(value)
    //   }
    // },
    // filtersTenders: {
    //   get () {
    //     return this.getFiltersTenders
    //   },
    //   set (value) {
    //     this.setTenders(value)
    //   }
    // },
    // filtersInfoBandi: {
    //   get () {
    //     return this.getFiltersInfoBandi
    //   },
    //   set (value) {
    //     this.setInfoBandi(value)
    //   }
    // },
    // filtersTenderResult: {
    //   get () {
    //     return this.getFiltersTenderResult
    //   },
    //   set (value) {
    //     this.setTenderResult(value)
    //   }
    // },
    // filtersBenchmark: {
    //   get () {
    //     return this.getFiltersBenchmark
    //   },
    //   set (value) {
    //     this.setBenchmark(value)
    //   }
    // },
    // filtersMessages: {
    //   get () {
    //     return this.getFiltersMessages
    //   },
    //   set (value) {
    //     this.setMessages(value)
    //   }
    // },
    // filtersTendersPagination: {
    //   get () {
    //     return this.getFiltersTenders.pagination
    //   },
    //   set (value) {
    //     this.setTendersPagination(value)
    //   }
    // },
    // filtersInfoBandiPagination: {
    //   get () {
    //     return this.getFiltersInfoBandi.pagination
    //   },
    //   set (value) {
    //     this.setInfoBandiPagination(value)
    //   }
    // },
    // filtersTenderResultPagination: {
    //   get () {
    //     return this.getFiltersTenderResult.pagination
    //   },
    //   set (value) {
    //     this.setTenderResultPagination(value)
    //   }
    // },
    // filtersBenchmarkPagination: {
    //   get () {
    //     return this.getFiltersBenchmark.pagination
    //   },
    //   set (value) {
    //     this.setFiltersBenchmarkPagination(value)
    //   }
    // },
    // filtersMessagesPagination: {
    //   get () {
    //     return this.getFiltersMessages.pagination
    //   },
    //   set (value) {
    //     this.setMessagesPagination(value)
    //   }
    // },
    // filtersToSyncro: {
    //   get () {
    //     return this.getFiltersToSyncro
    //   },
    //   set (value) {
    //     this.setToSyncro(value)
    //   }
    // },
    filtersSelectedSyncro: {
      get () {
        return this.getFilters('selected')['syncro'].filter(row => (row.disableSelectRow === false && row.selectTemporaryRow === false))
      },
      set (value) {
        this.setFilters({
          type: 'selected',
          key: 'syncro',
          value: value
        })
      }
    }
  }
}
