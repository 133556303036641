import ConfigFilters from 'config/ConfigFilters.json'
import axios from 'axios'

let cancelToken

function setDefaultState (resetHidden = true) {
  let defaultState = {}
  let noInputs = ['Buttons', 'Reset', 'Apply']
  for (let [key, value] of Object.entries(ConfigFilters)) {
    defaultState[key] = {}
    defaultState[key]['expansionHeight'] = 0
    defaultState[key]['filterapplied'] = false
    // eslint-disable-next-line no-unused-vars
    for (let [keyField, valueField] of Object.entries(value.fields)) {
      if (!noInputs.includes(valueField.type)) {
        if (valueField.type !== 'Hidden') {
          defaultState[key][valueField.keyFilter] = valueField.default ?? ''
        } else {
          if (resetHidden) {
            defaultState[key][valueField.keyFilter] = valueField.default ?? ''
          } else {
            defaultState[key][valueField.keyFilter] = state[key][valueField.keyFilter]
          }
        }
        if (valueField.type === 'Date') {
          defaultState[key][valueField.keyFilter + '_from'] = valueField.default ?? ''
          defaultState[key][valueField.keyFilter + '_to'] = valueField.default ?? ''
        }
      }
    }
  }
  return defaultState
}

// initial state
const state = JSON.parse(JSON.stringify(setDefaultState()))

const mutations = {
  resetStateFilters (state) {
    state = JSON.parse(JSON.stringify(setDefaultState()))
  },
  setFilters (state, payload) {
    state[payload.type][payload.key] = payload.value ?? ''
  },
  setFilterApplied (state, payload) {
    state[payload.type]['filterapplied'] = payload.value
  },
  resetFilters (state, payload) {
    state[payload] = JSON.parse(JSON.stringify(setDefaultState(false)[payload]))
  },
  setSelectedSyncro (state, value) {
    state.selected.syncro = value
  },
  resetSelectedSyncro (state) {
    state.selected.syncro = []
  }
}

const actions = {
  resetStateFilters ({ commit }) {
    commit('resetStateFilters')
  },
  setFilters ({ commit }, payload) {
    commit('setFilters', payload)
  },
  setFilterApplied ({ commit }, payload) {
    commit('setFilterApplied', payload)
  },
  resetFilters ({ commit }, payload) {
    commit('resetFilters', payload)
  },
  applyFilters ({ commit, dispatch }, payload) {
    commit('setFilterApplied', { type: payload, value: true })
    dispatch(payload + '/loadData', true, { root: true })
  },
  setSelectedSyncro ({ commit, dispatch, rootGetters }, value) {
    let getCountSelectableSyncroPlatform = rootGetters['user/getCountSelectableSyncroPlatform']
    let getInstallationSyncroPlatform = rootGetters['user/getInstallationSyncroPlatform']
    let IDs = [...value.map(obj => obj.id), ...getInstallationSyncroPlatform]
    let payload = IDs.filter((v, i, a) => a.indexOf(v) === i)
    if (getCountSelectableSyncroPlatform >= 0 && payload.length >= getCountSelectableSyncroPlatform) {
      dispatch('installations/setAllDisableSelectedMomentRow', payload, { root: true })
    } else {
      dispatch('installations/setAllEnableSelectedMomentRow', {}, { root: true })
    }
    commit('setSelectedSyncro', value)
  },
  resetSelectedSyncro ({ commit }) {
    commit('resetSelectedSyncro')
  },
  resetSelectSyncro ({ commit }, value) {
    let selected = Object.values(state.selected.syncro).filter(select => (select.id !== value))
    commit('setSelectedSyncro', selected)
  },
  loadFilterAggiudicatari ({ rootGetters }, payload) {
    // Cancel the previous axios token if any
    if (cancelToken) {
      cancelToken.cancel()
    }
    // Get a new token
    cancelToken = axios.CancelToken.source()

    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let url = stateSettingUrl.ws_benchmark_operatori_economici + (payload.filter ?? '_') + '/' + payload.index
    let config = {
      cancelToken: cancelToken.token,
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
    return axios.get(url, config)
      .then((response) => {
        return Object.values(response.data)
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          return error
        }
      })
  },
  loadFilterStazioneAppaltante ({ rootGetters }, payload) {
    // Cancel the previous axios token if any
    if (cancelToken) {
      cancelToken.cancel()
    }
    // Get a new token
    cancelToken = axios.CancelToken.source()

    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let url = stateSettingUrl.ws_benchmark_stazione_appaltante + (payload.filter ?? '_') + '/' + payload.index
    let config = {
      cancelToken: cancelToken.token,
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
    return axios.get(url, config)
      .then((response) => {
        return Object.values(response.data)
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          return error
        }
      })
  }
}

const getters = {
  getFilters: state => type => {
    return state[type]
  },
  getFilterApplied: state => type => {
    return state[type]['filterapplied']
  },
  getSelectedSyncro: state => {
    return state.selected.syncro
  },
  isSelectedSyncro: state => {
    return !!(state.selected.syncro).length
  },
  getCanSyncroAccount: state => {
    return Object.values(state.selected.syncro).filter(installation => (installation.statusSyncroAccount === 1 || installation.statusSyncroAccount === 0) && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getSelectedSyncroAccount: state => {
    return Object.values(state.selected.syncro).filter(installation => installation.statusSyncroAccount === 1 && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getCanSyncroSubscription: state => {
    return Object.values(state.selected.syncro).filter(installation => installation.statusSyncroAccount === 1 && (installation.statusSyncroIscrizione === 0 || installation.statusSyncroIscrizione === 1) && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getSelectedSyncroSubscription: state => {
    return Object.values(state.selected.syncro).filter(installation => installation.statusSyncroIscrizione === 3 && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getSelectedSyncroSubscriptionDisabled: state => {
    return Object.values(state.selected.syncro).filter(installation => installation.statusSyncroIscrizione === 100 && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
