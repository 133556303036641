import axios from 'axios'
import helpers from 'mixins/mixin-helpers'

const getDefaultState = () => {
  return {
    syncro: {
      progress: false
    },
    deSyncro: {
      idPlatform: '',
      progress: false
    },
    newToken: {
      idPlatform: '',
      progress: false
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateSyncro (state) {
    Object.assign(state, getDefaultState())
  },
  setSyncroProgress (state, value) {
    state.syncro.progress = value
  },
  setDeSyncroIdPlatform (state, value) {
    state.deSyncro.idPlatform = value
  },
  setDeSyncroProgress (state, value) {
    state.deSyncro.progress = value
  },
  setNewTokenIdPlatform (state, value) {
    state.newToken.idPlatform = value
  },
  setNewTokenProgress (state, value) {
    state.newToken.progress = value
  },
  setSyncro (state, syncro) {
    Object.assign(state.syncro, syncro)
  },
  setDeSyncro (state, deSyncro) {
    Object.assign(state.deSyncro, deSyncro)
  },
  setNewToken (state, newToken) {
    Object.assign(state.newToken, newToken)
  }
}

const actions = {
  resetStateSyncro ({ commit }) {
    commit('resetStateSyncro')
  },
  setSyncro ({ commit, dispatch }, value) {
    commit('setSyncro', value)
  },
  setSyncroProgress ({ commit, dispatch }, value) {
    commit('setSyncroProgress', value)
  },
  setDeSyncro ({ commit, dispatch }, value) {
    commit('setDeSyncro', value)
  },
  setDeSyncroIdPlatform ({ commit, dispatch }, value) {
    commit('setDeSyncroIdPlatform', value)
  },
  setDeSyncroProgress ({ commit, dispatch }, value) {
    commit('setDeSyncroProgress', value)
  },
  setNewToken ({ commit, dispatch }, value) {
    commit('setNewToken', value)
  },
  setNewTokenIdPlatform ({ commit, dispatch }, value) {
    commit('setNewTokenIdPlatform', value)
  },
  setNewTokenProgress ({ commit, dispatch }, value) {
    commit('setNewTokenProgress', value)
  },
  sendSyncroCredential: function ({ commit, rootGetters, dispatch, rootState }) {
    dispatch('dialogs/setSyncroDialog', true, { root: true })
    commit('setSyncroProgress', true)
    let selectedSyncro = rootGetters['filters/getSelectedSyncro']
    let index
    let selected = {}
    for (index in selectedSyncro) {
      let installation = rootGetters['installations/getPlatformById'](selectedSyncro[index].id)
      selected[selectedSyncro[index].id] = installation.url
      dispatch('installations/setDisableSelectRow', selectedSyncro[index].id, { root: true })
      if (rootGetters['user/isCountSelectableSyncroPlatform']) { // eeeee macarena!
        dispatch('user/setInstallationSyncroPlatform', selectedSyncro[index].id, { root: true })
      }
    }
    dispatch('filters/resetSelectedSyncro', true, { root: true })
    let url = rootGetters['settings/getSettingsUrls'].networkpa_sync_credential
    axios.post(url, {
      urlPlatform: selected
    },
    { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
      .then(() => {
        // commit('setSyncroProgress', false)
        // for (index in selectedSyncro) {
        //   dispatch('installations/setDisableSelectRow', selectedSyncro[index].id, { root: true })
        // }
      })
      .catch(() => {
        // commit('setSyncroProgress', false)
      })
  },
  sendDeSyncroCredential: function ({ store, commit, state, dispatch, rootGetters, rootState }) {
    commit('setSyncroProgress', true)
    let installation = rootGetters['installations/getPlatformById'](state.deSyncro.idPlatform)
    let selected = {}
    selected[state.deSyncro.idPlatform] = installation.url
    let url = rootGetters['settings/getSettingsUrls'].networkpa_desync_credential
    axios.post(url, {
      urlPlatform: selected
    },
    { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
      .then((response) => {
        dispatch('filters/resetSelectSyncro', state.deSyncro.idPlatform, { root: true })
        dispatch('installations/setDisableSelectRow', state.deSyncro.idPlatform, { root: true })
        commit('setSyncroProgress', false)
      })
      .catch(() => {
        commit('setSyncroProgress', false)
      })
  },
  sendSyncroSubscription: function ({ commit, rootGetters, dispatch, rootState }, selectedSyncro) {
    let index
    let selected = {}
    for (index in selectedSyncro) {
      let installation = rootGetters['installations/getPlatformById'](selectedSyncro[index].id)
      selected[selectedSyncro[index].id] = installation.url
    }
    if (!helpers.methods.isEmptyObject(selected)) {
      commit('setSyncroProgress', true)
    }
    if (helpers.methods.isArrayOrObjectDefinedNonEmpty(rootGetters['filters/getSelectedSyncroSubscription']) || helpers.methods.isArrayOrObjectDefinedNonEmpty(rootGetters['filters/getSelectedSyncroSubscriptionDisabled'])) {
      dispatch('dialogs/setSyncroSubscriptionDialog', true, { root: true })
    } else {
      dispatch('dialogs/setSyncroDialog', true, { root: true })
    }
    if (!helpers.methods.isEmptyObject(selected)) {
      var url = rootGetters['settings/getSettingsUrls'].networkpa_sync_subscription
      axios.post(url, {
        urlPlatform: selected
      },
      { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
      )
        .then(() => {
          dispatch('filters/resetSelectedSyncro', true, { root: true })
        })
        .catch(() => {
          commit('setSyncroProgress', false)
        })
    }
  },
  sendNewToken: function ({ commit, state, dispatch, rootGetters, rootState }) {
    commit('setSyncroProgress', true)
    let installation = rootGetters['installations/getPlatformById'](state.newToken.idPlatform)
    let selected = {}
    selected[state.newToken.idPlatform] = installation.url
    var url = rootGetters['settings/getSettingsUrls'].networkpa_new_token
    axios.post(url, {
      urlPlatform: selected
    },
    { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
      .then((response) => {
        commit('setSyncroProgress', false)
      })
      .catch(() => {
        commit('setSyncroProgress', false)
      })
  }
}

const getters = {
  getSyncroProgress: state => {
    return state.syncro.progress
  },
  getDeSyncro: state => {
    return state.deSyncro
  },
  getDeSyncroIdPlatform: state => {
    return state.deSyncro.idPlatform
  },
  getDeSyncroProgress: state => {
    return state.deSyncro.progress
  },
  getNewToken: state => {
    return state.newToken
  },
  getNewTokenIdPlatform: state => {
    return state.newToken.idPlatform
  },
  getNewTokenProgress: state => {
    return state.newToken.progress
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
