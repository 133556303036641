import { Loading } from 'quasar'
import { showErrorMessage } from 'functions/function-show-error-message'
import { showLoginErrorMessage, showLoginErrorExpired } from 'functions/function-show-login-error-message'
import { i18n } from 'boot/i18n'
import helpers from 'mixins/mixin-helpers'
import axios from 'axios'

const getDefaultState = () => {
  return {
    loggedIn: false,
    loaded: false
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateAuth (state) {
    Object.assign(state, getDefaultState())
  },
  setLoggedIn (state, value) {
    state.loggedIn = value
  },
  setLoaded (state, value) {
    state.loaded = value
  }
}
const actions = {
  resetStateAuth ({ commit }) {
    commit('resetStateAuth')
  },
  loginUser ({ commit, dispatch, rootGetters }, payload) {
    return axios.post(rootGetters['settings/getSettingsUrls'].base_url + '/npa-login', {
      signin: payload
    },
    { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
      .then((response) => {
        if (response.data.error) {
          if (response.data.status === 'expired') {
            showLoginErrorExpired()
          } else {
            showLoginErrorMessage()
          }
          this.$router.push(i18n.t('routes.auth')).catch(() => {})
        } else {
          this.$router.push(i18n.t('routes.home')).catch(() => {})
        }
        return true
      })
      .catch(() => {
        showErrorMessage('Connection Error')
        return true
      })
  },
  getJwtLogin ({ commit, dispatch, rootGetters }, payload) {
    return axios.post(rootGetters['settings/getSettingsUrls'].base_url + '/npa/get_jwt_login', {
      idPlatform: payload
    },
    {
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      timeout: 1000
    }
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return {
          error: true,
          data: error
        }
      })
  },
  logoutUser ({ dispatch, rootGetters }) {
    Loading.show()
    axios.post(rootGetters['settings/getSettingsUrls'].base_url + '/npa-logout', {}, { headers: { 'Content-type': 'application/x-www-form-urlencoded' } })
      .then(() => {
        dispatch('setLogout')
      })
      .catch(() => {
        showErrorMessage('Connection Error')
      })
  },
  getSessionStatus ({ dispatch, rootGetters, state }) {
    if (!state.loggedIn) {
      return axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/session_status')
        .then((response) => {
          if (!helpers.methods.isUndefined(response.data.error)) {
            return !response.data.error
          } else {
            return false
          }
        })
        .catch(() => {
          return false
        })
    } else {
      return true
    }
  },
  setLogin ({ commit, dispatch, rootGetters }, value = false) {
    if (!rootGetters['user/loaded']) {
      return dispatch('user/loadUser', true, { root: true })
        .then((response) => {
          if (response) {
            commit('setLoggedIn', true)
            commit('setLoaded', true)
            if (!process.env.DEV) {
              dispatch('notify/loadNotify', true, { root: true })
              dispatch('notify/loadNotifyRuntime', true, { root: true })
            }
            dispatch('installations/loadInstallations', true, { root: true })
            return true
          } else {
            return false
          }
        })
    }
  },
  setLogout ({ dispatch, commit }) {
    commit('resetStateAuth')
    dispatch('user/resetStateUser', null, { root: true })
    this.$router.push(i18n.t('routes.auth')).catch(() => {})
    dispatch('permissions/resetStatePermissions', null, { root: true })
    dispatch('messages/resetStateMessages', null, { root: true })
    dispatch('dialogs/resetStateDialogs', null, { root: true })
    dispatch('settings/resetStateSettings', null, { root: true })
    dispatch('tenders/resetStateTenders', null, { root: true })
    dispatch('documents/resetStateDocuments', null, { root: true })
    dispatch('installations/resetStateInstallations', null, { root: true })
    dispatch('filters/resetStateFilters', null, { root: true })
    dispatch('supplierForms/resetStateSupplierForms', null, { root: true })
    dispatch('syncro/resetStateSyncro', null, { root: true })
    dispatch('notify/resetStateNotify', null, { root: true })
    dispatch('notify/closeConnection', null, { root: true })
    dispatch('settings/loadConfigurations', null, { root: true })
  }
}

const getters = {
  getStateAuth: state => {
    return state
  },
  getAuthLoggedIn: state => {
    return state.loggedIn
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
