import ConfigPaginations from 'config/ConfigPaginations.json'

const getDefaultState = () => {
  return ConfigPaginations
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStatePaginations (state) {
    state = getDefaultState()
  },
  setPaginations (state, payload) {
    state[payload.type] = payload.value
  },
  setPaginationsKey (state, payload) {
    state[payload.type][payload.key] = payload.value
  },
  setPaginationForResponse (state, payload) {
    state[payload.type][payload.key] = payload.value
  }
}

const actions = {
  resetStatePaginations ({ commit }) {
    commit('resetStatePaginations')
  },
  setPaginations ({ commit }, payload) {
    commit('setPaginations', payload)
  },
  setPaginationsKey ({ commit }, payload) {
    commit('setPaginationsKey', payload)
  },
  setPaginationForResponse ({ commit }, payload) {
    commit('setPaginationForResponse', payload)
  }
}

const getters = {
  getPaginations: state => type => {
    return state[type]
  },
  getPaginationKey: state => payload => {
    return state[payload.type][payload.key]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
