import axios from 'axios'

export default class BackendAPI {
  constructor (options) {
    this.instance = axios.create(options)
    this.pendingRequests = {}
  }

  async request (method, endingPoint, data = {}, config = {
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  }) {
    let url = this.instance.defaults.baseURL + endingPoint
    this.managePendingRequests(url)
    try {
      const response = await this.instance.request({
        method,
        url,
        data,
        cancelToken: this.getCancelTokenToken(url),
        ...config
      })
      this.deletePendingRequest(url)
      return response.data
    } catch (error) {
      this.deletePendingRequest(url)
      this.handleError(error, url)
    }
  }

  get (endingPoint = '', params = {}, config = undefined) {
    return this.request('get', endingPoint, undefined, { params, ...config })
  }

  post (endingPoint = '', data = {}, config = undefined) {
    return this.request('post', endingPoint, data, config)
  }

  put (endingPoint = '', data = {}, config = undefined) {
    return this.request('put', endingPoint, data, config)
  }

  delete (endingPoint = '', config = undefined) {
    return this.request('delete', endingPoint, undefined, config)
  }

  managePendingRequests (url) {
    if (this.pendingRequests[url]) {
      this.pendingRequests[url].cancel()
    }
    this.generateCancelToken(url)
  }

  generateCancelToken (url) {
    this.pendingRequests[url] = axios.CancelToken.source()
  }

  getCancelToken (url) {
    return this.pendingRequests[url]
  }

  getCancelTokenToken (url) {
    return this.getCancelToken(url).token
  }

  deletePendingRequest (url) {
    delete this.pendingRequests[url]
  }

  handleError (error, url) {
    if (axios.isCancel(error)) {
      this.generateError('Request for ' + url + ' cancel from client', 499)
    } else {
      const response = error.response
      if (response) {
        this.generateError(response.data.message, response.status)
      } else {
        throw error
      }
    }
  }

  generateError (message, status) {
    const error = new Error(message)
    error.response = this.generateResponseError(status, message)
    throw error
  }

  generateResponseError (status, statusText = '') {
    const headers = new Headers({ 'Content-Type': 'text/plain' })
    const options = {
      status: status, // Codice di stato HTTP 499
      statusText: statusText, // Testo del codice di stato HTTP
      headers: headers, // Header HTTP
      url: '', // URL della risposta
      redirected: false, // Indica se la risposta è stata redirezionata
      ok: false, // Indica che la risposta non ha successo (codice di stato al di fuori di 200-299)
      body: null, // Contenuto della risposta come ReadableStream
      trailer: null // Trailer header HTTP
    }
    return new Response('Errore nella richiesta', options)
  }
}
