import BackendAPI from '../classes/backendAPI'
import { url } from '../classes/settingsStatic'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    benchmarksa: {
      data: [],
      loading: false,
      loaded: false,
      details: {},
      loadDetailsSA: false
    }
  }
}

const api = new BackendAPI({
  baseURL: url.ws_benchmark_gare
})
const apiDetails = new BackendAPI({
  baseURL: url.ws_benchmark_stazione_appaltante
})

// initial state
const state = getDefaultState()

const mutations = {
  resetStateBenchmarkSA (state) {
    Object.assign(state, getDefaultState())
  },
  setDataBenchmarkSA (state, value) {
    state.benchmarksa.data = value
  },
  setLoadingBenchmarkSA (state, value) {
    state.benchmarksa.loading = value
  },
  setLoadedBenchmarkSA (state, value) {
    state.benchmarksa.loaded = value
  },
  setDetailsBenchmarkSA (state, value) {
    state.benchmarksa.details = value
  },
  setLoadDetailsSA (state, value) {
    state.benchmarksa.loadDetailsSA = value
  }
}

const actions = {
  resetStateBenchmarkSA ({ commit }) {
    commit('resetStateBenchmarkSA')
  },
  loadData ({ commit, rootState, dispatch }) {
    commit('setLoadingBenchmarkSA', true)
    let data = {
      pagination: rootState.paginations.benchmarksa,
      filter: rootState.filters.benchmarksa
    }

    let config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }

    return api.post(undefined, data, config)
      .then((response) => {
        commit('setDataBenchmarkSA', { ...response.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'benchmarksa',
          key: 'rowsNumber',
          value: response.total
        }, { root: true })
        dispatch('user/loadBenchmark190', true, { root: true })
        commit('setLoadedBenchmarkSA', true)
        commit('setLoadingBenchmarkSA', false)
        return response
      })
      .catch(function (error) {
        const message = error.message
        const response = error.response

        if (response !== undefined) {
          if (response.status !== 499) {
            Vue.prototype.$q.dialog({
              component: 'DialogQueryFinished',
              parent: this,
              text: message
            })
            commit('setDataBenchmarkSA', [])
            commit('setLoadingBenchmarkSA', false)
          }
        } else {
          console.log(error)
        }
        return false
      })
  },
  loadDetailsBenchmarkSA ({ commit, rootState, dispatch }, id) {
    commit('setLoadDetailsSA', true)

    return apiDetails.get(id + '/info')
      .then((response) => {
        dispatch('user/loadBenchmark190', true, { root: true })
        // reperisco tutti gli anni
        let years = [...new Set(
          response.importi.filter(obj => obj.anno_aggiudicazione !== null)
            .map(obj => obj.anno_aggiudicazione)
        )]
        let payload = {
          denominazione: response.denominazione,
          years: response.importi.filter(obj => obj.range_importo === null && years.includes(obj.anno_aggiudicazione)),
          total: response.importi.find(obj => obj.range_importo === null && obj.anno_aggiudicazione === null)
        }
        commit('setDetailsBenchmarkSA', { ...payload })
        commit('setLoadDetailsSA', false)
        return true
      })
      .catch(function (error) {
        const message = error.message
        const response = error.response

        if (response !== undefined) {
          if (response.status !== 499) {
            Vue.prototype.$q.dialog({
              component: 'DialogQueryFinished',
              parent: this,
              text: message
            })
            commit('setDetailsBenchmarkSA', {})
            commit('setLoadDetailsSA', false)
          }
        } else {
          console.log(error)
        }
        return false
      })
  }
}

const getters = {
  getBenchmarkSA: state => {
    return Object.values(state.benchmarksa.data)
  },
  getLoadingBenchmarksa: state => {
    return state.benchmarksa.loading
  },
  getLoadedBenchmarksa: state => {
    return state.benchmarksa.loaded
  },
  getDetailsBenchmarkSA: state => {
    return state.benchmarksa.details
  },
  getLoadDetailsSA: state => {
    return state.benchmarksa.loadDetailsSA
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
