import Vue from 'vue'
import Quasar from 'quasar'
import axios from 'axios'
import { i18n } from 'boot/i18n.js'
import helpers from 'mixins/mixin-helpers'
let cancelTokenSource

const getDefaultState = () => {
  return {
    user: {
      data: [],
      user_plus: {
        IsPlusDataStart: null,
        IsPlusDataEnd: null
      },
      loaded: false
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateUser (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.user.data = value
  },
  setDataBenchmark190 (state, value) {
    state.user.data.user_information.benchmark190 = value
  },
  setLoaded (state, value) {
    state.user.loaded = value
  },
  setPreferLanguage (state, value) {
    state.user.data.user_preference.preferLanguage = value
  },
  updatePrefer (state, payload) {
    state.user.data[payload.key][payload.value] = !state.user.data[payload.key][payload.value]
  },
  addPrefer (state, payload) {
    Vue.set(state.user.data[payload.key], payload.value, true)
  },
  deletePrefer (state, payload) {
    Vue.delete(state.user.data[payload.key], payload.value)
  },
  setIsPlusDataStart (state, value) {
    state.user.user_plus.IsPlusDataStart = value
  },
  setIsPlusDataEnd (state, value) {
    state.user.user_plus.IsPlusDataEnd = value
  },
  setInstallationSyncroPlatform (state, value) {
    state.user.data.user_information.syncro_platform.installation.push(value)
    state.user.data.user_information.syncro_platform.installation = [...new Set(state.user.data.user_information.syncro_platform.installation)]
  },
  setBenchmark190MaxRequests (state, value) {
    state.user.data.user_information.benchmark190.max_requests = value
  }

}

const actions = {
  resetStateUser ({ commit }) {
    commit('resetStateUser')
  },
  setData ({ commit, dispatch }, value) {
    commit('setData', value)
  },
  setDataBenchmark190 ({ commit, dispatch }, value) {
    commit('setDataBenchmark190', value)
  },
  setLoaded ({ commit, dispatch }, value) {
    commit('setLoaded', value)
  },

  loadUser ({ commit, rootGetters, dispatch }) {
    return axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/server_user')
      .then((response) => {
        let dataProfile = response.data.data.profile
        dispatch('permissions/setData', dataProfile.user_information.permission, { root: true })
        commit('setData', dataProfile)
        dispatch('chooseLanguage', dataProfile)

        let dataConfiguration = response.data.data.configuration
        dispatch('settings/setConfiguration', dataConfiguration, { root: true })

        if (dataProfile.user_information.subscription) {
          commit('setIsPlusDataStart', dataProfile.user_information.subscription.date_start)
          commit('setIsPlusDataEnd', dataProfile.user_information.subscription.date_end)
        }
        dispatch('manageDialogs', dataProfile)
        commit('setLoaded', true)
        return true
      })
      .catch(() => {
        commit('setData', [])
        commit('setLoaded', true)
        return false
      })
  },
  loadBenchmark190 ({ commit, rootGetters, dispatch }) {
    if (cancelTokenSource) {
      cancelTokenSource.cancel()
    }
    // Get a new token
    cancelTokenSource = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let config = {
      cancelToken: cancelTokenSource.token,
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
    let url = stateSettingUrl.ws_benchmark_user_info
    return axios.post(url, {}, config)
      .then((response) => {
        commit('setDataBenchmark190', { ...response.data })
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          return false
        }
      })
  },
  manageDialogs ({ commit, dispatch }, dataProfile) {
    if (dataProfile.paperboy_last_news) {
      dispatch('dialogs/setNewsPaperBoyDialog', dataProfile.paperboy_last_news, { root: true })
      dispatch('dialogs/setPaperBoyNewsDialog', true, { root: true })
    } else {
    }
    if (dataProfile.user_information.password_modified) {
      dispatch('dialogs/setPasswordModifiedDialog', true, { root: true })
    }
    if (dataProfile.user_information.credential_syncro_error_email_used_dialog) {
      dispatch('dialogs/setCredentialSyncroErrorEmailUsedDialog', true, { root: true })
    } else {
      if (dataProfile.user_information.credential_syncro_email_sent_dialog) {
        dispatch('dialogs/setCredentialSyncroEmailSentDialog', true, { root: true })
      } else {
        if (dataProfile.user_information.credential_syncro_complete_dialog) {
          dispatch('dialogs/setCredentialSyncroCompleteDialog', true, { root: true })
        }
      }
    }
  },
  setPreferUser ({ commit, dispatch, rootGetters }, payload) {
    if (typeof state.user.data[payload.key][payload.value] !== 'undefined') {
      commit('deletePrefer', payload)
    } else {
      commit('addPrefer', payload)
    }
    axios.post(rootGetters['settings/getSettingsUrls'].base_url + '/set_prefer', {
        payload: payload
      },
      { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
  },
  setIsPlusDataStart ({ commit, dispatch }, value) {
    commit('setIsPlusDataStart', value)
  },
  setIsPlusDataEnd ({ commit, dispatch }, value) {
    commit('setIsPlusDataEnd', value)
  },
  chooseLanguage ({ commit, dispatch }, dataProfile) {
    let preferLanguage = dataProfile.user_preference.preferLanguage
    if (!helpers.methods.isUndefined(preferLanguage)) {
      if (preferLanguage === 'en') {
        preferLanguage = 'en-gb'
      }
    } else {
      preferLanguage = i18n.locale
      commit('setPreferLanguage', preferLanguage)
    }
    dispatch('setLocalLang', preferLanguage)
  },
  setInstallationSyncroPlatform ({ commit }, idPlatform) {
    commit('setInstallationSyncroPlatform', idPlatform)
  },
  setPreferLanguage ({ commit, state, dispatch, rootGetters }, value) {
    if (rootGetters['auth/getAuthLoggedIn']) {
      commit('setPreferLanguage', value)
      var url = rootGetters['settings/getSettingsUrls'].networkpa_preference
      axios.post(url, {
          preference: state.user.data.user_preference
        },
        { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
      )
        .then((response) => {
          if (!response.data.error) {
            location.reload()
          }
        })
    } else {
      dispatch('setLocalLang', value)
    }
  },
  setLocalLang ({ commit }, value) {
    i18n.locale = value
    import('quasar/lang/' + value).then(lang => {
      Quasar.lang.set(lang.default)
    })
  },
  setPaperboyReaded ({ rootGetters }) {
    let idPaperboy = rootGetters['dialogs/getNewsPaperBoyDialog'].id
    let url = rootGetters['settings/getSettingsUrls'].paperboy_set_readed + idPaperboy
    axios.get(url, {}
      // { headers: { 'X-Requested-With': 'XMLHttpRequest' } }
    )
  },
  setBenchmark190MaxRequests ({ commit }, value) {
    commit('setBenchmark190MaxRequests', value)
  }
}

const getters = {
  user: state => {
    return state.user
  },
  loaded: state => {
    return state.user.loaded
  },
  getPreferPlatform: state => {
    return state.user.data.prefer_platform
  },
  getPreferTender: state => {
    return state.user.data.prefer_tender
  },
  getUserPreference: state => {
    return state.user.data.user_preference
  },
  getPreferLanguage: state => {
    let preferLanguage = i18n.locale
    if (!helpers.methods.isUndefined(state.user.data.user_preference)) {
      preferLanguage = state.user.data.user_preference.preferLanguage
    }
    if (preferLanguage === 'en') {
      preferLanguage = 'en-gb'
    }
    return preferLanguage
  },
  getUserPlus: state => {
    return state.user.user_plus
  },
  getUserProfile: state => {
    return state.user.data.user_information
  },
  getCountSelectableSyncroPlatform: state => {
    return state.user.data.user_information.syncro_platform.countSelectable
  },
  isCountSelectableSyncroPlatform: state => {
    return state.user.data.user_information.syncro_platform.countSelectable >= 0
  },
  getRemainingSyncs: (state, getters) => {
    return getters.getCountSelectableSyncroPlatform - getters.getInstallationSyncroPlatform.length
  },
  getInstallationSyncroPlatform: state => {
    return state.user.data.user_information.syncro_platform.installation
  },
  getMaxRowMessages: state => {
    return state.user.data.user_information.syncro_platform.maxRowMessages
  },
  getMaxRowTenders: state => {
    return state.user.data.user_information.syncro_platform.maxRowTenders
  },
  getPercentageComplete: state => {
    return state.user.data.user_information.percentage_profile
  },
  getBenchmark190: state => {
    return state.user.data.user_information.benchmark190
  },
  getBenchmark190MaxRequests: state => {
    return state.user.data.user_information.benchmark190.max_requests
  },
  getBenchmark190Requests: state => {
    let requests = state.user.data.user_information.benchmark190.requests
    return Object.values(requests).filter(request => request.query !== undefined)
  },
  getBenchmark190Actions: state => {
    return state.user.data.user_information.benchmark190.actions
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
