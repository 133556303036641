import axios from 'axios'
import helpers from 'mixins/mixin-helpers'

const getDefaultState = () => {
  return {
    installations: {
      data: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateInstallations (state) {
    Object.assign(state, getDefaultState())
  },
  setInstallationsData (state, value) {
    state.installations.data = value
  },
  setPrefer (state, index) {
    state.installations.data[index].prefer = !state.installations.data[index].prefer
  },
  setDisableSelectRow (state, index) {
    state.installations.data[index].disableSelectRow = !state.installations.data[index].disableSelectRow
  },
  setAllDisableSelectedMomentRow (state, payload) {
    Object.keys(state.installations.data)
      .filter(key => !payload.includes(key))
      .forEach(function (key) {
        state.installations.data[key].selectTemporaryRow = true
      })
    // state.installations.data[index].selectTemporaryRow = true
  },
  setAllEnableSelectedMomentRow (state) {
    Object.keys(state.installations.data)
      .forEach(function (key) {
        state.installations.data[key].selectTemporaryRow = false
      })
  },
  setDisableSelectTemporaryRow (state, index) {
    state.installations.data[index].selectTemporaryRow = true
  },
  setEnableSelectTemporaryRow (state, index) {
    state.installations.data[index].selectTemporaryRow = false
  },
  setStatusSyncroAccount (state, index) {
    state.installations.data[index].statusSyncroAccount = state.installations.data[index].statusSyncroAccount ? 0 : 1
  },
  setStatusSyncroIscrizione (state, payload) {
    state.installations.data[payload.index].statusSyncroIscrizione = payload.value
  }
}

const actions = {
  resetStateInstallations ({ commit }) {
    commit('resetStateInstallations')
  },
  setPrefer ({ commit, dispatch }, payload) {
    commit('setPrefer', payload)
  },
  setDisableSelectRow ({ commit, dispatch }, payload) {
    commit('setDisableSelectRow', payload)
  },
  setAllDisableSelectedMomentRow ({ commit, state }, payload) {
    commit('setAllDisableSelectedMomentRow', payload)
  },
  setAllEnableSelectedMomentRow ({ commit, state }) {
    commit('setAllEnableSelectedMomentRow')
  },
  setStatusSyncroAccount ({ commit, dispatch }, payload) {
    commit('setStatusSyncroAccount', payload)
  },
  setStatusSyncroIscrizione ({ commit, dispatch }, payload) {
    commit('setStatusSyncroIscrizione', payload)
  },
  loadInstallations ({ commit, rootGetters, dispatch }) {
    axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/server_installations_w_user')
      .then((response) => {
        let dataStore = response.data.data.data
        commit('setInstallationsData', dataStore)
      })
      .catch(() => {
        commit('setInstallationsData', [])
      })
  }
}

const getters = {
  getStateInstallations: state => {
    return Object.values(state.installations.data).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
  },
  getStateInstallationsCount: (state, getters) => {
    return getters.getStateInstallations.length
  },
  getStateInstallationsAll: (state, getters) => {
    let installationsAll = getters.getStateInstallationsToSyncro
    installationsAll = installationsAll.concat(getters.getStateInstallationsCanEnable)
    installationsAll = installationsAll.concat(getters.getStateInstallationsInValidation)
    installationsAll = installationsAll.concat(getters.getStateInstallationsValidated)
    return Object.values(installationsAll).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
  },
  getStateInstallationsAllCount: (state, getters) => {
    return getters.getStateInstallationsAll.length
  },
  getStateInstallationsToSyncro: state => {
    return Object.values(state.installations.data).filter(installation => installation.statusSyncroAccount === 0).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
  },
  getStateInstallationsToSyncroCount: (state, getters) => {
    return getters.getStateInstallationsToSyncro.length
  },
  getStateInstallationsCanEnable: state => {
    let installationsAbilitation = Object.values(state.installations.data).filter(installation => helpers.methods.isArrayOrObjectDefinedNonEmpty(installation.abilitation) && installation.statusSyncroAccount === 1 && installation.albi_disabled === 0).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
    let result = []
    installationsAbilitation.map(function (el) {
      let platform = Object.values(el.abilitation).filter(abilitation => (abilitation.status === 1) || abilitation.expired)
      platform.forEach(function (node) {
        result.push({ ...el, ...node }
        )
      })
    })
    return result
  },
  getStateInstallationsCanEnableCount: (state, getters) => {
    return getters.getStateInstallationsCanEnable.length
  },
  getStateInstallationsInValidation: state => {
    let installationsAbilitation = Object.values(state.installations.data).filter(installation => helpers.methods.isArrayOrObjectDefinedNonEmpty(installation.abilitation) && installation.statusSyncroAccount === 1 && installation.albi_disabled === 0).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
    let result = []
    installationsAbilitation.map(function (el) {
      let platform = Object.values(el.abilitation).filter(abilitation => abilitation.status === 2)
      platform.forEach(function (node) {
        result.push({ ...el, ...node }
        )
      })
    })
    return result
  },
  getStateInstallationsInValidationCount: (state, getters) => {
    return getters.getStateInstallationsInValidation.length
  },
  getStateInstallationsValidated: state => {
    let installationsAbilitation = Object.values(state.installations.data).filter(installation => helpers.methods.isArrayOrObjectDefinedNonEmpty(installation.abilitation) && installation.statusSyncroAccount === 1 && installation.albi_disabled === 0).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
    let result = []
    installationsAbilitation.map(function (el) {
      let platform = Object.values(el.abilitation).filter(abilitation => abilitation.status === 3)
      platform.forEach(function (node) {
        result.push({ ...el, ...node }
        )
      })
    })
    return result
  },
  getStateInstallationsValidatedCount: (state, getters) => {
    return getters.getStateInstallationsValidated.length
  },
  getStateInstallationsExpiring: state => {
    let installationsAbilitation = Object.values(state.installations.data).filter(installation => helpers.methods.isArrayOrObjectDefinedNonEmpty(installation.abilitation) && installation.statusSyncroAccount === 1 && installation.albi_disabled === 0).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
    let result = []
    installationsAbilitation.map(function (el) {
      let platform = Object.values(el.abilitation).filter(abilitation => abilitation.expiring)
      platform.forEach(function (node) {
        result.push({ ...el, ...node }
        )
      })
    })
    return result
  },
  getStateInstallationsExpiringCount: (state, getters) => {
    return getters.getStateInstallationsExpiring.length
  },
  getStateInstallationsExpired: state => {
    let installationsAbilitation = Object.values(state.installations.data).filter(installation => helpers.methods.isArrayOrObjectDefinedNonEmpty(installation.abilitation) && installation.statusSyncroAccount === 1 && installation.albi_disabled === 0).sort((a, b) => Number(b.prefer) - Number(a.prefer) || helpers.filters.sortingText(a.name, b.name))
    let result = []
    installationsAbilitation.map(function (el) {
      let platform = Object.values(el.abilitation).filter(abilitation => abilitation.expired)
      platform.forEach(function (node) {
        result.push({ ...el, ...node }
        )
      })
    })
    return result
  },
  getStateInstallationsExpiredCount: (state, getters) => {
    return getters.getStateInstallationsExpired.length
  },
  getStateInstallationsWithSyncroSubscription: state => {
    return Object.values(state.installations.data).filter(installation => (installation.statusSyncroIscrizione === 1))
  },
  getStateInstallationsWithSyncroSubscriptionCount: (state, getters) => {
    return getters.getStateInstallationsWithSyncroSubscription.length
  },
  getStateInstallationsPrefer: state => {
    return Object.values(state.installations.data).filter(installation => (installation.prefer === true)).sort((a, b) => helpers.filters.sortingText(a.name, b.name))
  },
  getStateInstallationsPreferCount: (state, getters) => {
    return getters.getStateInstallationsPrefer.length
  },
  getPlatformById: (state) => (id) => {
    return state.installations.data[id]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
